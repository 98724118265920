@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 100%; /* Cambiado de 111% a 100% */
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 100%; /* Cambiado de 111% a 100% */
  }

  #portfolio {
    width: 100%; /* Cambiado de 110% a 100% */
  }
}

/* Solucionar el desplazamiento horizontal */
body {
  overflow-x: hidden;
}

/* Estilizar el scroll */
body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

body::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 3px solid #f1f1f1;
}

body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Estilos para el contenedor de FloatingWhatsApp */
.floating-whatsapp-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  max-width: 100%;
  max-height: 100%;
  z-index: 1000;
}

.floating-whatsapp-container > div {
  width: auto !important;
  height: auto !important;
  max-width: 100%;
  max-height: 100%;
}